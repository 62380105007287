import React from 'react'
import { useEffect, useState } from 'react';
import axios from 'axios';
import AuthUser from './AuthUser';
import toast, { Toaster } from "react-hot-toast";
import { useNavigate } from 'react-router-dom';

export default function StudentAdd() {
    const navigate = useNavigate();
    const { http, user } = AuthUser();
    const [users, setUsers] = useState([]);
    const [masterdata, setmasterdata] = useState([]);
    const [masterdataIndi, setMasterdataIndi] = useState([]);
    const [cat, setCat] = useState([]);
    const [devision, setDevision] = useState([]);
    const [standard, setstandard] = useState([]);
    const [location, setLocation] = useState([]);
    const [lastadm, setLastadm] = useState([]);
    const [agent, setAgent] = useState([]);
    const [error, setError] = useState(null);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [formData, setFormData] = useState({
        adm_no: '',
        assigned_by: user.id,
        adm_date: '',
        gvt_no: '',
        class: '',
        section: '',
        roll_no: '',
        sibling_study: '',
        group_name: '',
        dob: '',
        category: '',
        mobile: '',
        cast: '',
        religion: '',
        adhar: '',
        email: '',
        club: '',
        photo: "",
        father: "",
        mother: "",
        father_occ: "",
        mother_phone: "",
        mother_occu: "",
        guardian_name: "",
        guardian_occu: "",
        guardian_relation: "",
        guardian_phone: "",
        ext_1: "",
        ext_2: "",
        ext_3: "",
        ext_4: "",
        ext_5: "",
        ext_6: "",
        ext_7: "",
        ext_8: "",
        ext_9: "",
        ext_10: "New",
        ext_11: "",
        ext_12: "",
        ext_13: "",
        ext_14: "",
        ext_15: "",
        ext_16: "",
        ext_17: "",
        ext_18: "",
        tc_num: "",
        company_id: user.company_id,
        user_id: user.id,
    });

    const initialState = {
        adm_no: '',
        assigned_by: user.id,
        adm_date: '',
        gvt_no: '',
        class: '',
        section: '',
        roll_no: '',
        sibling_study: '',
        group_name: '',
        dob: '',
        category: '',
        mobile: '',
        cast: '',
        religion: '',
        adhar: '',
        email: '',
        club: '',
        photo: "",
        ext_1: "",
        ext_2: "",
        ext_3: "",
        ext_4: "",
        ext_5: "",
        ext_6: "",
        ext_7: "",
        ext_8: "",
        ext_9: "",
        ext_10: "",
        ext_11: "",
        ext_12: "",
        ext_13: "",
        ext_14: "",
        ext_15: "",
        ext_16: "",
        tc_num: "",
        company_id: user.company_id,
        user_id: user.id,
    }

    const handleChange = (e) => {
        let value = e.target.value;
        if (e.target.name === 'ext_2' || e.target.name === 'ext_3' || e.target.name === 'father' || e.target.name === 'mother' || e.target.name === 'guardian_name') {
            value = value.toUpperCase();
        }
        setFormData({
            ...formData,
            [e.target.name]: value
        });

        if (e.target.name === 'category') {
            fetchUsersForDepartment(e.target.value);
        }

        if (e.target.name === 'master_id') {
            fetchMasterIndividual(e.target.value)
        }
    };

    const fetchUsersForDepartment = async (departmentId) => {
        try {
            const response = await http.get(`/master_cat?cat=${departmentId}`);
            setmasterdata(response.data.users);
        } catch (error) {
            console.error("Error fetching users:", error);
        }

    }

    const fetchMasterIndividual = async (msterId) => {
        try {
            const response = await http.get(`/master_cat_indi?cat=${msterId}`);
            setMasterdataIndi(response.data.users);
        } catch (error) {
            console.error("Error fetching users:", error);
        }

    }

    useEffect(() => {
        http.get(`/devision?login_user=${user.id}`).then((res) => {
            setDevision(res.data.data);
        });

        http.get(`/agent?company_id=${user.company_id}`).then((res) => {
            setAgent(res.data.data);
        });

        http.get(`/last_adm?login_user=${user.id}&company_id=${user.company_id}`).then((res) => {
            setLastadm(res.data.data);
        });

        http.get(`/location?company_id=${user.company_id}`).then((res) => {
            setLocation(res.data.data);
        });

        http.get(`/class?company_id=${user.company_id}`).then((res) => {
            setstandard(res.data.data);
        });

        http.get(`/department_user_company?&login_user=${user.id}`).then((res) => {
            setUsers(res.data.users);
        })

        http.get(`/category`).then((res) => {
            setCat(res.data.data);
        })

    }, [])

    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth',
        });
    };


    const handleSubmit = async (e) => {
        e.preventDefault();
        setIsSubmitting(true);
        setError(null); // Reset error state
        const form = new FormData();

        for (const key in formData) {
            form.append(key, formData[key]);
        }

        const taskFileInput = document.querySelector('input[type="file"]');
        if (taskFileInput.files[0]) {
            form.append("photo", taskFileInput.files[0]);
        }

        try {
            const response = await http.post('/student_add', form, {
                headers: {
                    "Content-Type": "multipart/form-data",
                },
            });

            toast.success("Profile data added successfully!");
            setFormData(initialState);
            navigate('/student_list_all');
        } catch (error) {
            console.error('Error adding student:', error);
            setError(error.response?.data?.message || 'An error occurred while adding the student'); // Set the error message
        } finally {
            setIsSubmitting(false);
            scrollToTop();
        }
    };

    const DropdownOption = ({ user }) => {
        return (
            <option key={user.id} value={user.id}>
                {user.item_name}
            </option>
        );
    };


    return (
        <>
            <div className="container mt-2 row">
                <Toaster />
                {error && <div className="error-message alert alert-danger">{error}</div>} {/* Display the error message */}
                <h3>Add Student</h3>
                <form onSubmit={handleSubmit}>
                    <div className='row'>
                        <div className='col-md-4 border p-4 mx-1'>
                            <h4>Personal Details</h4>

                            <div className='form-group mt-3'>
                                <label>Last Admission: <b> {lastadm ? lastadm.adm_no : ''}</b></label>

                            </div>

                            <div className='form-group mt-3'>
                                <label>Student Type</label>
                                <select className="form-control" name="ext_10" value={formData.ext_10}
                                    onChange={handleChange} required>
                                    <option value="New">New</option>
                                    <option value="Old">Old</option>
                                </select>
                            </div>

                            <div className='form-group mt-3'>
                                <label>Admission No:</label>
                                <input
                                    name="adm_no"
                                    className='form-control'
                                    value={formData.adm_no}
                                    onChange={handleChange}
                                    required
                                />
                            </div>

                            <div className='form-group mt-3'>
                                <label>Admission Date</label>
                                <input
                                    name="adm_date"
                                    className='form-control'
                                    value={formData.adm_date}
                                    onChange={handleChange}
                                    type='date'

                                />
                            </div>

                            <div className='form-group mt-3'>
                                <label>First Name</label>
                                <input
                                    name="ext_2"
                                    className='form-control'
                                    value={formData.ext_2}
                                    onChange={handleChange}
                                    required
                                />
                            </div>

                            <div className='form-group mt-3'>
                                <label>Last Name</label>
                                <input
                                    name="ext_3"
                                    className='form-control'
                                    value={formData.ext_3}
                                    onChange={handleChange}
                                />
                            </div>

                            <div className='form-group mt-3'>
                                <label>Gender</label>
                                <select className="form-control" name="ext_9" value={formData.ext_9}
                                    onChange={handleChange}>
                                    <option value="">Select Any</option>
                                    <option value="Male">Male</option>
                                    <option value="Fe-Male">Fe-Male</option>
                                </select>
                            </div>

                            <div className='form-group mt-3'>
                                <label>STS Number</label>
                                <input
                                    name="gvt_no"
                                    className='form-control'
                                    value={formData.gvt_no}
                                    onChange={handleChange}

                                />
                            </div>

                            <div className='form-group mt-3'>
                                <label>Class</label>
                                <select className="form-control" name="class" value={formData.class}
                                    onChange={handleChange} required>
                                    <option value="">Select</option>
                                    {standard.map((depart) => (
                                        <option key={depart.id} value={depart.class_name} >
                                            {depart.class_name}
                                        </option>
                                    ))}

                                </select>
                            </div>


                            <div className='form-group mt-3'>
                                <label>Section</label>
                                <select className="form-control" name="section" value={formData.section}
                                    onChange={handleChange} required>
                                    <option value="">Select</option>
                                    {devision.map((depart) => (
                                        <option key={depart.id} value={depart.name}>
                                            {depart.name}
                                        </option>
                                    ))}

                                </select>
                            </div>

                            <div className='form-group mt-3'>
                                <label>Roll No</label>
                                <input
                                    name="roll_no"
                                    className='form-control'
                                    value={formData.roll_no}
                                    onChange={handleChange}

                                />
                            </div>

                            <div className='form-group mt-3'>
                                <label>Sibling Name & Class</label>
                                <input
                                    name="ext_7"
                                    className='form-control'
                                    value={formData.ext_7}
                                    onChange={handleChange}
                                />
                            </div>

                            <div className='form-group mt-3'>
                                <label>Identity Marks</label>
                                <input
                                    name="ext_8"
                                    className='form-control'
                                    value={formData.ext_8}
                                    onChange={handleChange}
                                />
                            </div>

                            <div className='form-group mt-3'>
                                <label>Group Name</label>
                                <input
                                    name="group_name"
                                    className='form-control'
                                    value={formData.group_name}
                                    onChange={handleChange}

                                />
                            </div>

                            <div className='form-group mt-3'>
                                <label>Date Of Birth</label>
                                <input
                                    name="dob"
                                    className='form-control'
                                    value={formData.dob}
                                    onChange={handleChange}

                                    type='date'
                                />
                            </div>

                            <div className='form-group mt-3'>
                                <label>Category</label>
                                <select
                                    name="category"
                                    className='form-control'
                                    value={formData.category}
                                    onChange={handleChange}
                                >
                                    <option value="">Select</option>
                                    <option value="A">A</option>
                                    <option value="B">B</option>
                                    <option value="C">C</option>
                                    <option value="D">D</option>
                                    <option value="E">E</option>
                                    <option value="F">F</option>
                                    <option value="G">G</option>
                                    <option value="H">H</option>
                                    <option value="I">I</option>
                                    <option value="J">J</option>
                                    <option value="K">K</option>
                                    <option value="L">L</option>
                                    <option value="M">M</option>
                                    <option value="N">N</option>
                                    <option value="O">O</option>
                                    <option value="P">P</option>
                                    <option value="Q">Q</option>
                                    <option value="R">R</option>
                                    <option value="S">S</option>
                                    <option value="T">T</option>
                                    <option value="U">U</option>
                                    <option value="V">V</option>
                                    <option value="W">W</option>
                                    <option value="X">X</option>
                                    <option value="Y">Y</option>
                                    <option value="Z">Z</option>


                                </select>
                            </div>

                            <div className='form-group mt-3'>
                                <label>Mobile Number</label>
                                <input
                                    name="mobile"
                                    className='form-control'
                                    value={formData.mobile}
                                    onChange={handleChange}

                                />
                            </div>

                            <div className='form-group mt-3'>
                                <label>Religion</label>
                                <input
                                    name="religion"
                                    className='form-control'
                                    value={formData.religion}
                                    onChange={handleChange}

                                />
                            </div>

                            <div className='form-group mt-3'>
                                <label>Caste</label>
                                <input
                                    name="cast"
                                    className='form-control'
                                    value={formData.cast}
                                    onChange={handleChange}

                                />
                            </div>


                            <div className='form-group mt-3'>
                                <label>Caste Category</label>
                                <input
                                    name="ext_15"
                                    className='form-control'
                                    value={formData.ext_15}
                                    onChange={handleChange}

                                />
                            </div>

                            <div className='form-group mt-3'>
                                <label>Aadhaar No/UID</label>
                                <input
                                    name="adhar"
                                    className='form-control'
                                    value={formData.adhar}
                                    onChange={handleChange}

                                />
                            </div>

                            <div className='form-group mt-3'>
                                <label>Email</label>
                                <input
                                    name="email"
                                    className='form-control'
                                    value={formData.email}
                                    onChange={handleChange}

                                />
                            </div>

                            <div className='form-group mt-3'>
                                <label>Other Remark</label>
                                <input
                                    name="club"
                                    className='form-control'
                                    value={formData.club}
                                    onChange={handleChange}

                                />
                            </div>

                            {(user.status === 1 && (user.department_id === 1)) && (
                                <div className='form-group mt-3'>
                                    <label>Agent</label>

                                    <select name="ext_11"
                                        className='form-control'
                                        value={formData.ext_11}
                                        onChange={handleChange}
                                        required
                                    >
                                        <option value="">Select any</option>
                                        {agent.map((depart) => (
                                            <option key={depart.id} value={depart.name}>
                                                {depart.name}
                                            </option>
                                        ))}
                                    </select>

                                </div>
                            )}

                            {(user.status === 1 && (user.department_id === 1)) && (
                                <div className='form-group mt-3'>
                                    <label>Agent amount</label>
                                    <input
                                        name="ext_17"
                                        className='form-control'
                                        value={formData.ext_17}
                                        onChange={handleChange}

                                    />
                                </div>
                            )}

                            {/* <div className='form-group mt-3'>
                                <label>Agent amount</label>
                                <input
                                    name="ext_18"
                                    className='form-control'
                                    value={formData.ext_18}
                                    onChange={handleChange}

                                />
                            </div> */}

                            <div className='form-group mt-3'>
                                <label>Other Remark</label>
                                <input
                                    name="club"
                                    className='form-control'
                                    value={formData.club}
                                    onChange={handleChange}

                                />
                            </div>

                            <div className='form-group mt-3'>
                                <label>Transport</label>
                                <select className="form-control" name="ext_12" value={formData.ext_12}
                                    onChange={handleChange} required >
                                    <option value="">Select Any</option>
                                    <option value="Yes">Yes</option>
                                    <option value="No">No</option>
                                </select>
                            </div>

                            {formData.ext_12 !== "No" && (
                                <>
                                    <div className='form-group mt-3'>
                                        <label>Way</label>
                                        <select className="form-control" name="ext_13" value={formData.ext_13} onChange={handleChange}>
                                            <option value="">Select Any</option>
                                            <option value="One-Way">One Way</option>
                                            <option value="Two-Way">Two Way</option>
                                        </select>
                                    </div>

                                    <div className='form-group mt-3'>
                                        <label>Pickup Location</label>
                                        <select name="ext_16" className='form-control' value={formData.ext_16} onChange={handleChange}>
                                            <option value="">Select any</option>
                                            {location.map((depart) => (
                                                <option key={depart.id} value={depart.name}>
                                                    {depart.name}
                                                </option>
                                            ))}
                                        </select>
                                    </div>
                                </>
                            )}

                            <div className='form-group mt-3'>
                                <label>Previous Class</label>
                                <input
                                    name="ext_14"
                                    className='form-control'
                                    value={formData.ext_14}
                                    onChange={handleChange}

                                />
                            </div>

                            <div className="form-group mt-3">
                                <label>Photo</label>
                                <input type="file" className="form-control" name='photo' onChange={handleChange} />
                            </div>


                        </div>

                        <div className='col-md-4 border  p-4 mx-2'>
                            <h4>Parent / Guardian's Details</h4>

                            <div className='form-group mt-3'>
                                <label>Father's Name</label>
                                <input
                                    name="father"
                                    className='form-control'
                                    value={formData.father}
                                    onChange={handleChange}

                                />
                            </div>

                            <div className='form-group mt-3'>
                                <label>'Father's Phone</label>
                                <input
                                    name="ext_1"
                                    className='form-control'
                                    value={formData.ext_1}
                                    onChange={handleChange}

                                />
                            </div>

                            <div className='form-group mt-3'>
                                <label>Father's Occupation</label>
                                <input
                                    name="father_occ"
                                    className='form-control'
                                    value={formData.father_occ}
                                    onChange={handleChange}

                                />
                            </div>

                            <div className='form-group mt-3'>
                                <label>Mother's Name</label>
                                <input
                                    name="mother"
                                    className='form-control'
                                    value={formData.mother}
                                    onChange={handleChange}

                                />
                            </div>

                            <div className='form-group mt-3'>
                                <label>Mother's Phone</label>
                                <input
                                    name="mother_phone"
                                    className='form-control'
                                    value={formData.mother_phone}
                                    onChange={handleChange}

                                />
                            </div>


                            <div className='form-group mt-3'>
                                <label>Mother's Occupation</label>
                                <input
                                    name="mother_occu"
                                    className='form-control'
                                    value={formData.mother_occu}
                                    onChange={handleChange}

                                />
                            </div>

                            <div className='form-group mt-3'>
                                <label>Guardian's Name</label>
                                <input
                                    name="guardian_name"
                                    className='form-control'
                                    value={formData.guardian_name}
                                    onChange={handleChange}

                                />
                            </div>

                            <div className='form-group mt-3'>
                                <label>Guardian's Relation</label>
                                <input
                                    name="guardian_relation"
                                    className='form-control'
                                    value={formData.guardian_relation}
                                    onChange={handleChange}
                                />
                            </div>

                            <div className='form-group mt-3'>
                                <label>Guardian's Occupation</label>
                                <input
                                    name="guardian_occu"
                                    className='form-control'
                                    value={formData.guardian_occu}
                                    onChange={handleChange}

                                />
                            </div>

                            <div className='form-group mt-3'>
                                <label>Guardian's Phone</label>
                                <input
                                    name="guardian_phone"
                                    className='form-control'
                                    value={formData.guardian_phone}
                                    onChange={handleChange}

                                />
                            </div>

                            <div className='form-group mt-3'>
                                <label>Guardian's Address</label>
                                <textarea name="guardian_address"
                                    className='form-control'
                                    value={formData.guardian_address}
                                    onChange={handleChange}
                                ></textarea>
                            </div>
                            <hr />

                            <div className='border border-success p-3'>

                                <div className='form-group mt-3'>
                                    <label>Current Address</label>
                                    <textarea name="ext_4"
                                        className='form-control'
                                        value={formData.ext_4}
                                        onChange={handleChange}
                                    ></textarea>
                                </div>

                                <div className='form-group mt-3'>
                                    <label>Permanent Address</label>
                                    <textarea name="ext_5"
                                        className='form-control'
                                        value={formData.ext_5}
                                        onChange={handleChange}
                                    ></textarea>
                                </div>
                            </div>
                        </div>

                        <div className='col-md-3 border  p-2 px-2'>
                            <h4>Miscellaneous Details</h4>

                            <div className='form-group mt-3'>
                                <label>Previous School Details</label>
                                <textarea name="pre_school"
                                    className='form-control'
                                    value={formData.pre_school}
                                    onChange={handleChange}
                                >

                                </textarea>
                            </div>

                            <div className='form-group mt-3'>
                                <label>Head</label>
                                <input
                                    name="tc_num"
                                    className='form-control'
                                    value={formData.tc_num}
                                    onChange={handleChange}

                                />
                            </div>

                            {/* <div className='form-group mt-3'>
                                <label>National Identification Number</label>
                                <input
                                    name="national_id"
                                    className='form-control'
                                    value={formData.national_id}
                                    onChange={handleChange}
                                    required
                                />
                            </div> */}

                            {/* <div className='form-group mt-3'>
                                <label>Local Identification Number</label>
                                <input
                                    name="local_id"
                                    className='form-control'
                                    value={formData.local_id}
                                    onChange={handleChange}

                                />
                            </div> */}
                            {/* <div className='p-2 border border-success mt-2 p-2'>
                                <div className='form-group mt-3'>
                                    <label>Bank Account Number</label>
                                    <input
                                        name="bank_account"
                                        className='form-control'
                                        value={formData.bank_account}
                                        onChange={handleChange}
                                        required
                                    />
                                </div>
                                <div className='form-group mt-3'>
                                    <label>Bank Name</label>
                                    <input
                                        name="bank_name"
                                        className='form-control'
                                        value={formData.bank_name}
                                        onChange={handleChange}
                                        required
                                    />
                                </div>

                                <div className='form-group mt-3'>
                                    <label>IFC</label>
                                    <input
                                        name="ext_5"
                                        className='form-control'
                                        value={formData.ext_5}
                                        onChange={handleChange}
                                        required
                                    />
                                </div>
                            </div> */}
                        </div>
                    </div>

                    <div className='col-md-12'>
                        <button className='btn button btn-success mt-3 w-100 mb-3' type="submit" disabled={isSubmitting}>Add</button>
                    </div>
                </form>

            </div>
        </>
    )
}
